"use strict";
//--------------------------
// Ready                   |
//--------------------------
$(document).ready(function () {

    $(document).on('click', 'a[href="#"]', function (e) { e.preventDefault(); });

    // Init object-fit polyfill for ie 11
    objectFitImages();
    // --------------------------------------------------------------

    // Multi attach
    multiAttach();
    // --------------------------------------------------------------

    // Header menu scroll
    if ($('.js-h-menu-scroll').length) {
        var hMenuScroll = new Swiper('.js-h-menu-scroll', {
            slidesPerView: 'auto',
            freeMode: true,
            observer: true,
            observeParents: true,
            touchReleaseOnEdges: true,
            freeModeMomentumBounce: false
        });
    }
    // --------------------------------------------------------------

    // Main slider
    if ($('.js-main-slider').length) mainSlider();
    // --------------------------------------------------------------

    // Recently Viewed Slider
    if ($('.js-rv-slider').length) {
        var rvSlider = new Swiper('.js-rv-slider', {
            slidesPerView: 2,
            spaceBetween: 2,
            init: false,
            navigation: {
                prevEl: '.js-rv-slider-prev',
                nextEl: '.js-rv-slider-next'
            },
            breakpoints: {
                768: {
                    slidesPerView: 3
                },
                1440: {
                    slidesPerView: 4
                }
            }
        });
        rvSlider.on('init resize', function () { needArrows(this) });
        rvSlider.init();
    }
    // --------------------------------------------------------------

    // Product page sliders
    if ($('.p-sliders-wrap').length) {
        var slidesLengthFlag = $('.p-slider-thumb .swiper-wrapper .swiper-slide').length !== 0 ? true : false;
        var galleryThumbs = new Swiper('.p-slider-thumb', {
            direction: 'vertical',
            spaceBetween: 8,
            slidesPerView: 'auto',
            slideToClickedSlide: true,
            observer: true,
            observeParents: true,
            watchOverflow: true,
            breakpoints: {
                768: {
                    centeredSlides: slidesLengthFlag,
                    centeredSlidesBounds: true
                }
            }
        });
        var sliderMain = new Swiper('.p-slider-main', {
            effect: 'fade',
            fadeEffect: { crossFade: true },
            observer: true,
            observeParents: true,
            navigation: false,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            thumbs: {
                swiper: galleryThumbs,
                autoScrollOffset: 2
            }
        });

        var pSlides = $('.p-slider-main .swiper-wrapper .swiper-slide');
        var videoIndexes = [];
        pSlides.each(function (i) {
            if ($(this).hasClass('video-slide')) videoIndexes.push($(this).index());
        });
        var pPaginationBullets = $('.p-slider-main .swiper-pagination-bullet');
        for (var i = 0; i < videoIndexes.length; i++) {
            $(pPaginationBullets[videoIndexes[i]]).addClass('video-bullet');
        }
    }
    // --------------------------------------------------------------

    if (document.querySelector(".tab-slider")) {
      const wrappers = Array.from(document.querySelectorAll(".tab-slider"));
      wrappers.forEach((wrapper) => {
        const sliderElemMain = wrapper.querySelector(".tab-slider__main .swiper");
        const sliderElemPreview = wrapper.querySelector(".tab-slider__preview .swiper");
        const sliderPreview = new Swiper(sliderElemPreview, {
          slidesPerView: 4,
          spaceBetween: 12,
          breakpoints: {
            768: {
              direction: 'vertical',
              //slidesPerView: 4,
              spaceBetween: 20
            }
          }
        });
        const sliderMain = new Swiper(sliderElemMain, {
          thumbs: {
            swiper: sliderPreview,
            //autoScrollOffset: 2
          }
        });
      });
    }

    // Similar articles Slider
    if ($('.js-sa-slider').length) {
        var saSlider = new Swiper('.js-sa-slider', {
            slidesPerView: 1,
            spaceBetween: 32,
            init: false,
            navigation: {
                prevEl: '.js-sa-slider-prev',
                nextEl: '.js-sa-slider-next'
            },
            breakpoints: {
                768: {
                    slidesPerView: 2
                }
            }
        });
        saSlider.on('init resize', function () { needArrows(this) });
        saSlider.init();

        var secTitleHeight = $('.sec-similar-articles .sec-title').outerHeight();
        var secTitleMargin = parseInt($('.sec-similar-articles .sec-title').css('margin-bottom'));
        var promoImgHeight = $('.promo-card__img').outerHeight();
        $('.js-sa-slider-prev, .js-sa-slider-next').css('top', secTitleHeight + secTitleMargin + promoImgHeight / 2);
    }
    // --------------------------------------------------------------

    // Team Slider
    if ($('.js-team-slider').length) {
        var teamSlider = new Swiper('.js-team-slider', {
            slidesPerView: 1,
            spaceBetween: 32,
            init: false,
            navigation: {
                prevEl: '.js-team-slider-prev',
                nextEl: '.js-team-slider-next'
            },
            breakpoints: {
                500: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 4
                }
            }
        });
        teamSlider.on('init resize', function () { needArrows(this) });
        teamSlider.init();

        var secTitleHeight = $('.sec-team .sec-title').outerHeight();
        var secTitleMargin = parseInt($('.sec-team .sec-title').css('margin-bottom'));
        var empImgHeight = $('.emp-card__img').outerHeight();
        $('.js-team-slider-prev, .js-team-slider-next').css('top', secTitleHeight + secTitleMargin + empImgHeight / 2);
    }
    // --------------------------------------------------------------

    // Default Slider
    if (document.querySelector(".swiper_default")) {
      const sliderWrappers = Array.from(document.querySelectorAll(".swiper_default"));
      sliderWrappers.forEach((sliderWrapper) => {
        const isOneSlide = sliderWrapper.classList.contains("swiper_one-slide");
        const swiperContainer = sliderWrapper.querySelector(".swiper-container");
        const prevBtn = sliderWrapper.querySelector(".slider-arrow--prev");
        const nextBtn = sliderWrapper.querySelector(".slider-arrow--next");
        const swiperDefault = new Swiper(swiperContainer, {
          slidesPerView: 1,
          spaceBetween: 32,
          loop: true,
          navigation: {
            prevEl: prevBtn,
            nextEl: nextBtn
          },
          breakpoints: {
            768: {
              slidesPerView: isOneSlide ? 1 : 2
            }
          }
        })
        //console.log(swiperDefault)
      });
    }
    // --------------------------------------------------------------

    // Variety use slider
    if ($('.js-variety-use-slider').length) {
        $('.js-variety-use-slider').each(function (i) {
            var thisWrap = $(this).closest('.sec-variety-use');
            var varietySlider = new Swiper($(this), {
                loop: true,
                centeredSlides: true,
                loopAdditionalSlides: 2,
                slidesPerView: 1,
                spaceBetween: 2,
                navigation: {
                    prevEl: $(thisWrap).find('.js-variety-use-slider-prev')[0],
                    nextEl: $(thisWrap).find('.js-variety-use-slider-next')[0]
                }
            });
            varietySlider.on('transitionStart', function () {
                var i = varietySlider.realIndex;
                var $slideText = $(thisWrap).find('.variety-use-slider__text p');

                $slideText.not(i).removeClass('active');
                $slideText.eq(i).addClass('active');
            });
        });
    }
    // --------------------------------------------------------------

    // Tabs on text page
    $(document).on('click', '.tabs__link', function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.closest(".tabs--animate")) return;

      var _id = $(this).attr('href');

      $(this).closest('.tabs').find('.tabs__link').removeClass('is-active');
      $(this).closest('.tabs').find('.tabs__block').removeClass('is-active');

      $(this).addClass('is-active');
      $(_id).addClass('is-active');
    });
    if (document.querySelector(".tabs--animate")) {
      const wrappers = Array.from(document.querySelectorAll(".tabs--animate"));
      wrappers.forEach((wrapper) => {
        const links = Array.from(wrapper.querySelectorAll(".tabs__link"));
        const blocks = Array.from(wrapper.querySelectorAll(".tabs__block"));

        links.forEach((link, ind) => {
          if (link.classList.contains("is-active")) {
            //blocks[ind].slideUp();
          } else {
            $(blocks[ind]).slideUp();
          }
        });
        
      });
    }
    if (document.querySelector("[data-menu-title]")) {
      const header = document.querySelector("header");
      const innerMenu = document.createElement("div");
      innerMenu.classList.add("inner-menu");
      innerMenu.innerHTML = `
        <button class="inner-menu__trigger">Разделы<span></span></button>
        <div class="inner-menu__wrapper">
          <div class="container">
            <div class="row">
              <div class="col-md-9 offset-md-3">
                <div class="inner-menu__inner"><ul class="inner-menu__list"></ul></div>
              </div>
            </div>
          </div>
        </div>
      `;
      header.after(innerMenu);
      const innerMenuList = document.querySelector(".inner-menu__list");
      const anchors = Array.from(document.querySelectorAll("[data-menu-title]"));
      anchors.forEach((link, index) => {
        const newItem = document.createElement("li");
        newItem.classList.add("inner-menu__item");
        if (!link.id) {
          link.setAttribute("id", `inner-${index}`)
        }
        newItem.innerHTML = `<a class="inner-menu__link" href="#${link.id}" data-offset-link="70">${link.dataset.menuTitle}</a>`;
        innerMenuList.append(newItem);
      })
    }
    $(document).on('click', '.tabs--animate .tabs__link', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.classList.contains("is-active")) return;
        const id = $(this).attr('href');
        console.log(id); 
        const wrapper = this.closest(".tabs--animate");
        console.log(wrapper)
        const tabs = Array.from(wrapper.querySelectorAll(".tabs__link"));
        const blocks = Array.from(wrapper.querySelectorAll(".tabs__block"));
        tabs.forEach((tab) => tab.classList.remove("is-active"));
        this.classList.add("is-active");
        blocks.forEach((block) => {
          if (block.classList.contains("is-active")) {
            $(block).slideUp();
            block.classList.remove("is-active");
          }
        });
        const targetBlock = document.querySelector(id);
        $(targetBlock).slideDown();
        targetBlock.classList.add("is-active");
        //wrapper.querySelector("")
        //var _id = $(this).attr('href');
        //$(this).closest('.tabs--animate').find('.tabs__block').slideDown();
        //$(_id).slideUp();
    });
    $(document).on('click', '.inner-menu__trigger', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".inner-menu").toggleClass("inner-menu--opened")
    });
    // --------------------------------------------------------------

  if (document.querySelector(".inner-menu")) {
    const linkElements = Array.from(document.querySelectorAll(".inner-menu__link"));
    const links = [];
    linkElements.forEach((link) => {
      const startId = link.getAttribute("href");
      const endId = link.hasAttribute("data-href-end") ? link.dataset.hrefEnd : link.getAttribute("href");
      const obj = {
        el: link,
        start: document.querySelector(startId),
        end: document.querySelector(endId),
        offset: link.hasAttribute("data-offset-link") ? link.dataset.offsetLink : 0
      }
      links.push(obj);
    });
    window.addEventListener('scroll', () => {
      links.forEach((link) => {
        if (window.scrollY >= link.start.offsetTop - link.offset && window.scrollY < link.start.offsetTop + link.start.offsetHeight) {
          link.el.classList.add('active');
        } else {
          link.el.classList.remove('active');
        }
      })
      //document.querySelectorAll('section').forEach((sec) => {
      //  let menuLink = document.querySelector(`.menu-item[href="#${sec.id}"]`);
      //  if (window.scrollY >= sec.offsetTop && window.scrollY < sec.offsetTop + sec.offsetHeight) {
      //    menuLink.classList.add('active');
      //  } else {
      //    menuLink.classList.remove('active');
      //  }
      //});
    });
  }
  if (document.querySelector('a[href^="#"]:not([href="#"])')) {
    const links = Array.from(document.querySelectorAll('a[href^="#"]:not([href="#"])'));
    links.forEach((link) => {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        const targetId = this.getAttribute('href');
        const target = document.querySelector(targetId);
        let offsetLink = 0;
        if (link.hasAttribute('data-offset-link')) {
          offsetLink = link.getAttribute('data-offset-link');
        }
        scrollToElement(target, offsetLink, 500, function () {
          if (targetId.includes("open") && !!target.openItem) {
            target.openItem();
          }
        });

      });
    })
  }

    // Input phone mask
    $('.js-input-phone').inputmask();
    // --------------------------------------------------------------

    // Datepicker
    const dateInputs = Array.from(document.querySelectorAll('.input--date input[type="date"]'));
    const inputDateProtoValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value");
    dateInputs.forEach((input) => {
      Object.defineProperty(input, "value", {
        get: inputDateProtoValue.get,
        set(value) {
          const oldValue = this.value;
          const resultValue = inputDateProtoValue.set.call(this, value);
          if (oldValue != value) this.dispatchEvent(new Event("change"));
          return resultValue;
        }
      });
    });
    $('.js-input-date').datepicker({
        position: "bottom right",
        onSelect: function(formattedDate, date, inst) {  
          const dateInput = inst.$el[0].parentElement.querySelector('input[type="date"]');        
          if (!!dateInput) {
            dateInput.value = date.getFullYear() + '-' + ('0'+ (date.getMonth()+1)).slice(-2) + '-' +
              ('0'+ date.getDate()).slice(-2);
          }
        }
    });

    // --------------------------------------------------------------

    // Range sliders
    if (typeof $().ionRangeSlider !== "undefined") rangeFilter();
    // --------------------------------------------------------------

    // Filter hide show
    $(document).on('click', '.js-tab-filter', function () {
        $(this).toggleClass('is-open');
        $(this).parent().next().stop().slideToggle(200);
    });
    // --------------------------------------------------------------

    // Tags
    $(document).on('click', '.tag', function () {
        $(this).toggleClass('is-active');
    });
    // --------------------------------------------------------------

    // Dropdown
    $(document).on('click', '.dropdown', function () {
        if (!$(this).hasClass('is-disable')) $(this).addClass('is-open');
    });
    $(document).on('click', function (e) {
        var container = $('.dropdown');
        if (!$(e.target).closest(container).length && $(container).hasClass('is-open'))
            $('.dropdown').removeClass('is-open');
    });

    // Stiky Sidebar
    if ($('.js-sticky-block').length && $(window).width() >= 768) {
        var topOffset = $(window).width() >= 1024 ? 60 : 20;
        $('.js-sticky-block').stick_in_parent({
            offset_top: topOffset
        });
    }
    // --------------------------------------------------------------

    // Accordion
    const accordeons = Array.from(document.querySelectorAll(".accordion"));
    accordeons.forEach((accordeon) => {
      const openedItem = accordeon.querySelector(".is-open");
      if (openedItem) {
        const body = openedItem.querySelector(".accordion__body");
        $(body).slideDown(300);
      }
    });
    $(document).on('click', '.js-acc-btn', function (e) {
        if ($(this).parent().parent().hasClass('accordion_only-one')) return;
        if ($(e.target).hasClass('sb-accordion__mob-head')) {
            $(this).toggleClass('is-open');
        } else $(this).parent().toggleClass('is-open');
        $(this).next().stop().slideToggle(300);
    });
    if (document.querySelector(".accordion_only-one")) {
      const accordeonsSwitches = Array.from(document.querySelectorAll(".accordion_only-one"));
      accordeonsSwitches.forEach((accordeon) => {
        accordeon.addEventListener("click", function (e) {
          if (e.target.closest(".js-acc-btn")) {
            const item = e.target.closest(".accordion__item");
            const body = item.querySelector(".accordion__body");
            if (item.classList.contains("is-open")) {
              item.classList.remove("is-open");
              $(body).slideUp(300);
            } else {
              const openedItem = accordeon.querySelector(".is-open");
              const openedBody = accordeon.querySelector(".is-open .accordion__body");
              if (!!openedItem) {
                openedItem.classList.remove("is-open");
                $(openedBody).slideUp(300);
              }
              item.classList.add("is-open");
              $(body).slideDown(300);
            }
          }
        });
      })
    }
    // --------------------------------------------------------------

    // Tooltips
    $('.tooltip').tooltipster({
        theme: 'tooltipster-shadow',
        maxWidth: 400,
        trigger: 'ontouchstart' in window || navigator.maxTouchPoints ? 'click' : 'hover',
        functionPosition: function (instance, helper, position) {
            if ($(window).width() >= 1024) {
                var offset = (position.size.width - helper.origin.offsetWidth) / 2;
                position.coord.left += offset;
                return position;
            }
        }
    });
    $('.tooltip-center').tooltipster({
        theme: 'tooltipster-shadow',
        maxWidth: 400,
        trigger: 'ontouchstart' in window || navigator.maxTouchPoints ? 'click' : 'hover'
    });
    // --------------------------------------------------------------

    // Popup open
    let blockEventPopup = false;
    $(document).on('click', '.js-popup-open', function () {
        blockEventPopup = true;
        var _id = $(this).attr('href');
        $(_id).fadeIn(200, () => {
          $(_id).addClass('popup_opened');
          blockEventPopup = false;
        });
    });
    // Popup close
    document.addEventListener("click", (e) => {
      const openedPopup = document.querySelector(".popup_opened");
      const datePickerContainer = document.querySelector(".datepickers-container");
      if (!!openedPopup && !blockEventPopup && !e.composedPath().includes(datePickerContainer)) {
        const popup = openedPopup.querySelector(".popup");
        if (!e.composedPath().includes(popup) || e.target.closest('.js-popup-close')) {
          $(this).removeClass('popup_opened');
          $('.popup-overlay').fadeOut(200);
        }
      }
    });
    // --------------------------------------------------------------

    // Likes with counter
    $(document).on('click', '.js-like-btn', function () {
        var $counter = $(this).parent().find('.news-likes__counter');
        var counterVal = parseInt($counter.text());

        $(this).toggleClass('is-active');

        if ($(this).hasClass('is-active')) {
            counterVal++;
            $counter.text(counterVal);
        } else {
            counterVal--;
            $counter.text(counterVal);
        }
    });
    // --------------------------------------------------------------

    // Add to favorites
    $(document).on('click', '.js-add-to-favorites', function () {
        $(this).toggleClass('is-active');
    });
    // --------------------------------------------------------------

    // Header Menu Desktop
    $(document).on('click', '.js-menu-btn', function () {
        var hMenuHeight = $('.js-h-menu').innerHeight();

        $('body').attr('data-pos', $(window).scrollTop());
        $('.layout').addClass('is-fixed').outerHeight(hMenuHeight);
        $('.js-h-menu').show();
        $(window).scrollTop(0);
    });

    $(document).on('click', '.js-menu-close', function () {
        $('.layout').removeClass('is-fixed').css('height', 'auto');
        $('.js-h-menu').hide();
        $(window).scrollTop($('body').attr('data-pos'));
        $('body').removeAttr('data-pos');
    });
    // --------------------------------------------------------------

    // Hamburger menu mobile
    $(document).on('click', '.js-hamburger', function () {
        var mobMenuHeight = $('.js-mob-menu').innerHeight();

        $(this).toggleClass('is-active');

        if ($('.js-hamburger').hasClass('is-active')) {
            $('.layout').addClass('is-fixed').outerHeight(mobMenuHeight);
            $('.js-mob-menu').addClass('is-visible');
        } else {
            $('.js-mob-menu').removeClass('catalog-open');
            $('.layout').removeClass('is-fixed').css('height', 'auto');
            $('.js-mob-menu').removeClass('is-visible');
        }
    });
    // --------------------------------------------------------------

    // Mobile-menu
    $(document).on('click', '.js-mob-catalog-btn', function () {
        var mobCatalogHeight = $('.js-mob-catalog').innerHeight();

        $('.layout').outerHeight(mobCatalogHeight);
        $(this).closest('.js-mob-menu').addClass('catalog-open');
    });

    $(document).on('click', '.js-mob-catalog-close', function () {
        var mobMenuHeight = $('.js-mob-menu').innerHeight();

        $('.layout').outerHeight(mobMenuHeight);
        $(this).closest('.js-mob-menu').removeClass('catalog-open');
    });
    // --------------------------------------------------------------

    // Mobile catalog btn
    $(document).on('click', '.js-h-catalog-btn', function () {
        $('.js-mob-menu').addClass('catalog-open');
        $('.js-hamburger').addClass('is-active');
        $('.js-mob-menu').addClass('is-visible');
        var mobCatalogHeight = $('.js-mob-catalog').innerHeight();
        $('.layout').outerHeight(mobCatalogHeight);
    });
    // --------------------------------------------------------------

    // Video block
    $(document).on('click', '.js-video-play', function () {
        var $thisVideo = $(this).closest('.video');
        $(this).parent().fadeOut();
        $thisVideo.find('.video__frame').fadeIn('fast');
        if ($thisVideo.find('.video__html5').length)
            $thisVideo.find('.video__html5')[0].play();
    });
    if (document.querySelector("[data-autoplay]")) {
      const apVideos = document.querySelectorAll("[data-autoplay]");
      apVideos.forEach((video) => {
        const videoPoster = video.querySelector(".video__poster");
        const videoWrap = video.querySelector(".video__frame");
        const videoEl = video.querySelector("video");
        let isRun = false;
        window.addEventListener('scroll', () => {
          if (window.scrollY >= videoPoster.offsetTop && window.scrollY < videoPoster.offsetTop + videoPoster.offsetHeight) {
            //console.log("No view");
          } else {
            if (!isRun) {
              isRun = true;
              setTimeout(() => {
                $(videoPoster).fadeOut();
                $(videoWrap).fadeIn('fast');
                videoEl.play();
              }, 1500);
            }
          }
        });
        //$(function () {
        //  var $video = $(videoEl);
        //  var $window = $(window);
        //  let isOn = false;
        //  console.log($video)
        //  $window.scroll(function () {
        //    var $topOfVideo = $video.offset().top;
        //    var $bottomOfVideo = $video.offset().top + $video.outerHeight();

        //    var $topOfScreen = $window.scrollTop();
        //    var $bottomOfScreen = $window.scrollTop() + $window.innerHeight();
        //    console.log(videoPoster.offset)
        //    if (window.scrollY >= videoEl.offsetTop - videoEl.offset && window.scrollY < videoEl.offsetTop + videoEl.offsetHeight) {
        //      console.log("In view")
        //    } else {
        //      console.log("no view")

        //    }
            //if (($bottomOfScreen > $bottomOfVideo) && ($topOfScreen < $topOfVideo)) {
            //  //$video[0].play();
            //  console.log("In view")
            //  if (!isOn) {
            //    $(videoPoster).fadeOut();
            //    $(videoWrap).fadeIn('fast');
            //  }
            //  $video.play();
            //  isOn = true;
            //} else {
            //  console.log("No view")
            //  $video.pause();
            //}
            //if ($video.is(":in-viewport")) {
            //  setTimeout(() => {
            //  }, 2000);
            //} else {
            //  $video[0].pause();
            //}
        //  });
        //});
      });
    }
    // --------------------------------------------------------------

    // Custom select
    $(document).on('click', '.select__in', function () {
        var $thisSelect = $(this).closest('.select');

        $('.select').not($thisSelect).removeClass('is-opened');
        $(this).closest('.select').toggleClass('is-opened');
    });

    $(document).on('click', '.select__item', function () {
        var $thisSelect = $(this).closest('.select');

        $thisSelect.find('.select__input').val($(this).attr('data-value'));
        $thisSelect.find('.select__in').html($(this).html());
        $thisSelect.find('.select__item').removeClass('is-active');
        $(this).addClass('is-active');
        $thisSelect.removeClass('is-opened');
    });

    $(document).on('click', function (e) {
        var container = $('.select');
        if (!$(e.target).closest(container).length && $('.select').hasClass('is-opened'))
            $('.select').removeClass('is-opened');
    });

    $(document).on('keyup', function (e) {
        if (e.keyCode == 27) {
            $('.select').removeClass('is-opened');
            $('.dropdown').removeClass('is-open');
        }
    });
    // --------------------------------------------------------------

    // Phone select
    $(document).on('click', '.js-phone-select', function () {
        $(this).addClass('open');
        $(this).find('.phone-select__list').fadeIn(300);
    });

    // $(document).on('click', '.js-phone-list li', function () {
    //     var $commonParent = $(this).closest('.header-top__phone');
    //     var $phoneLink = $commonParent.find('.phone-link');
    //     var thisNum = $(this).data('phone-link');
    //     var thisText = $(this).text();

    //     $phoneLink.attr('href', thisNum).text(thisText);
    //     $('.js-phone-list li').removeClass('selected');
    //     $(this).addClass('selected');
    // });

    $(document).on('click', function (e) {
        var $container = $('.js-phone-select');
        var $containerList = $container.find('.phone-select__list');
        if (!$(e.target).closest($container).length && $containerList.is(':visible')) {
            $container.removeClass('open');
            $containerList.fadeOut(300);
        }
    });
    // --------------------------------------------------------------

    // Scroll to id
    $(document).on('click', '.js-scroll-to', function (e) {
        e.preventDefault();
        var _id = $(this).attr('href');
        $('html, body').animate({ scrollTop: $(_id).offset().top }, 1000);
    });
    // --------------------------------------------------------------

    // Contacts map init
    if ($('#contacts_map').length) initContactsMap();

    if ($('.cl-filter__section--btn').length) {
        var windowHeight = $(window).height();
        var btnPosTop = $('.filter-reset').offset().top;
        filterFixedButton(windowHeight, btnPosTop);
    }

}); // end ready



//--------------------------
// Resize trigger          |
//--------------------------

$(window).resize(function () {

    if ($(this).width() <= 1279) {
        $(document).off('click', '.js-footer-acc').on('click', '.js-footer-acc', function () {
            $(this).toggleClass('is-open');
            $(this).next().stop().slideToggle(200);
        });
    } else {
        $(document).off('click', '.js-footer-acc');
    }


    if ($('.cl-filter__section--btn').length) {
        var blockPadding = parseInt($('.cl-filter__section--btn').css('padding-left'));
        var btnWidth = $('.cl-filter__section--btn').width();
        var btnPosLeft = $('.cl-filter__section--btn').offset().left + blockPadding;
        if ($('.filter-reset').hasClass('is-fixed')) {
            $('.filter-reset').css({
                'width': btnWidth,
                'left': btnPosLeft
            });
        }
    }

}).trigger('resize');


$(window).on('orientationchange', function () {
    $(window).one('resize', function () {
        if ($('.cl-filter__section--btn').length) {
            filterNoFixedBtn();
            var windowHeight = $(window).height();
            var btnPosTop = $('.filter-reset').offset().top;
            filterFixedButton(windowHeight, btnPosTop);
        }
    });
});


$(window).on("load", function () {
    snow();
    if (typeof (localStorage) !== "undefined" && !localStorage.getItem("nyAnimation")) {
        localStorage.setItem("lastTime", new Date());
        localStorage.setItem("nyAnimation", true);
        animation();
    }
    else {
        if (localStorage.getItem("lastTime")) {
            var lastDate = new Date(localStorage.getItem("lastTime"));
            var nowDate = new Date();
            var result = (nowDate - lastDate) / 1000 / 60 / 60 / 24;
            if (result > 1) {
                localStorage.setItem("lastTime", new Date());
                animation();
            }
        }
    }
});



//--------------------------
// Functions               |
//--------------------------
function filterFixedButton(windowHeight, btnPosTop) {
    var filterPosTop = $('.cl-filter').offset().top;
    var blockPadding = parseInt($('.cl-filter__section--btn').css('padding-left'));
    var btnWidth = $('.cl-filter__section--btn').width();
    var btnHeight = $('.filter-reset').outerHeight();
    var btnPosLeft = $('.cl-filter__section--btn').offset().left + blockPadding;

    if (($(window).scrollTop() + windowHeight) <= (btnPosTop + btnHeight))
        filterFixedBtn(btnWidth, btnPosLeft);

    if (($(window).scrollTop() + windowHeight) <= (filterPosTop + 150))
        filterNoFixedBtn();

    $(window).off('scroll').on('scroll', function () {
        var scrollTop = $(window).scrollTop();
        btnWidth = $('.cl-filter__section--btn').width();
        btnPosLeft = $('.cl-filter__section--btn').offset().left + blockPadding;

        if (!$('.filter-reset').hasClass('is-fixed')) {
            if ((scrollTop + windowHeight) <= (btnPosTop + btnHeight))
                filterFixedBtn(btnWidth, btnPosLeft);
        } else {
            if ((scrollTop + windowHeight) > (btnPosTop + btnHeight))
                filterNoFixedBtn();
        }
        if ((scrollTop + windowHeight) <= (filterPosTop + 150))
            filterNoFixedBtn();
    });
}

function filterFixedBtn(btnWidth, btnPosLeft) {
    $('.filter-reset').addClass('is-fixed');
    $('.filter-reset').css({
        'width': btnWidth,
        'left': btnPosLeft
    });
}
function filterNoFixedBtn() {
    $('.filter-reset').removeClass('is-fixed');
    $('.filter-reset').css({
        'width': '100%',
        'left': 'auto'
    });
}

// Main Slider
function mainSlider() {
    var mainSliderAutoplay = $('.js-main-slider').data('swiper-autoplay');
    var mainSliderTransition = $('.js-main-slider').data('swiper-transition');
    var mainSlider = new Swiper('.js-main-slider', {
        direction: 'vertical',
        loop: true,
        speed: mainSliderTransition,
        autoplay: {
            delay: mainSliderAutoplay,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                return `<span class="` + className + `"><span class="num">` + (index + 1) + `</span>
                            <svg class="progress" width="38" height="38">
                                <circle cx="19" cy="19" r="18" fill="none" stroke="#fff" stroke-width="2"/>
                            </svg>
                        </span>`;
            }
        }
    });

    var nextSlider = new Swiper('.js-next-slider', {
        loop: true,
        speed: 800,
        allowTouchMove: false
    });

    mainSlider.controller.control = nextSlider;
    nextSlider.controller.control = mainSlider;

    var interval;
    mainSlider.on('transitionStart', function () {
        var i = mainSlider.realIndex;
        var $conts = $('.slide-content');

        $conts.not(i).removeClass('active');
        $conts.eq(i).addClass('active');

        mainSliderProgressStop();
        mainSliderProgress(mainSliderTransition);
    });

    var bullets = $('.swiper-pagination-bullet');
    var circle = bullets.eq(0).find('circle')[0];
    var radius = circle.r.baseVal.value;
    var circumference = radius * 2 * Math.PI;

    function mainSliderProgress(transition) {
        var activeBullet = $('.swiper-pagination-bullet-active');
        var circle = activeBullet.find('circle')[0];
        var intervalTick = mainSliderAutoplay / circumference;
        if (transition) intervalTick = (mainSliderAutoplay + transition) / circumference;

        circle.style.strokeDashoffset = circumference;

        interval = setInterval(function () {
            circumference--;
            circle.style.strokeDashoffset = circumference;
            if (circumference < 1) {
                mainSliderProgressStop();
            }
        }, intervalTick);

    }
    mainSliderProgress();

    function mainSliderProgressStop() {
        clearInterval(interval);
        circumference = radius * 2 * Math.PI;
    }

    $('.swiper-pagination-bullet').on('click', function () {
        mainSliderProgressStop();
        if ($(this).hasClass('swiper-pagination-bullet-active')) {
            $(this).toggleClass('pause');
            if ($(this).hasClass('pause')) {
                mainSlider.autoplay.stop();
                mainSliderProgressStop();
            } else {
                mainSlider.autoplay.start();
                mainSliderProgress();
            }
        } else {
            $('.swiper-pagination-bullet').removeClass('pause');
            mainSlider.autoplay.start();
        }
    });

    var slidesLength = $('.js-main-slider .swiper-wrapper .swiper-slide').length - 2;
    $(document).on('click', '.js-next-slide', function () {
        var next = mainSlider.realIndex + 1;
        if (next >= slidesLength) next = 0;
        $('.swiper-pagination-bullet').eq(next).click();
    });
}

// Hide arrows
function needArrows(swiper) {
    if (swiper.isBeginning && swiper.isEnd) {
        $(swiper.navigation.prevEl).hide();
        $(swiper.navigation.nextEl).hide();
    } else {
        $(swiper.navigation.prevEl).show();
        $(swiper.navigation.nextEl).show();
    }
}

// Multiattach
function multiAttach() {
    var $inputFile = $('.js-input-file');
    var $addFile = $('.js-add-file');
    var $filesList = $('.js-files-list');
    var files = [];

    $(document).on('change', '.js-input-file', function () {
        var newFiles = [];
        for (var index = 0; index < $inputFile[0].files.length; index++) {
            var file = $inputFile[0].files[index];
            newFiles.push(file);
            files.push(file);
        }

        newFiles.forEach(function (file) {
            var fileElement = $(`<li><span>${file.name}<span class="js-file-remove"></span></span></li>`);
            fileElement.data('fileData', file);
            $filesList.append(fileElement).show();
            $addFile.css('display', 'inline-block');

            fileElement.on('click', '.js-file-remove', function (event) {
                var fileElement = $(event.target).closest('li');
                var indexToRemove = files.indexOf(fileElement.data('fileData'));
                fileElement.remove();
                files.splice(indexToRemove, 1);
                if (!files.length) {
                    $filesList.hide();
                    $addFile.hide();
                }
            });
        });
    });

    $(document).on('click', '.js-add-file', function () {
        $inputFile.click();
    });
}

// Filter with Range slider
function rangeFilter() {
    var $parent = $('.js-rs-filter'),
        $range = $('.js-ion-rs');

    $range.ionRangeSlider({
        type: 'single',
        skin: 'round',
        hide_from_to: true,
        to_shadow: true,
        onStart: function (data) {
            var thisInput = data.slider.closest($parent).find('.js-rs-input');
            thisInput.prop("value", data.from);
        },
        onChange: function (data) {
            var thisInput = data.slider.closest($parent).find('.js-rs-input');
            thisInput.prop("value", data.from);
        }
    });

    $(document).on('input', '.js-rs-input', function () {
        var val = $(this).prop('value'),
            instance = $(this).closest($parent).find('.js-ion-rs').data("ionRangeSlider");

        instance.update({ from: val });
    });
}

function initContactsMap() {
    ymaps.ready(function () {
        var myMap = new ymaps.Map('contacts_map', {
            center: [53.861508, 27.685589],
            zoom: 16,
            //controls: ['smallMapDefaultSet']
        }, {
            searchControlProvider: 'yandex#search'
        }),

            myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
                hintContent: 'blum',
                //balloonContent: 'blum'
            }, {
                // Опции.
                // Необходимо указать данный тип макета.
                iconLayout: 'default#image',
                // Своё изображение иконки метки.
                iconImageHref: 'images/svg/marker.svg',
                // Размеры метки.
                iconImageSize: [124, 124],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                iconImageOffset: [-62, -52]
            });

        myMap.geoObjects.add(myPlacemark);

        // Запрещаем зум скроллом
        myMap.behaviors.disable('scrollZoom');
        // Запрещаем перетаскивание карты на мобилках(перетаскивание остается двумя пальцами)
        if ($(window).width() <= 767) myMap.behaviors.disable('drag');
    });
}

function scrollToElement(target, offset, duration, callback) {
  const targetPos =
    target.getBoundingClientRect().top + window.scrollY - offset;
  const startPos = window.scrollY;
  const distance = targetPos - startPos;

  let startTime = null;
  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPos, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    } else {
      callback();
    }
  }

  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  requestAnimationFrame(animation);
}

function animation() {
    // Variables
    var koefValue = 9;
    var timeKoef = 800;
    var cartMovingValue = $(".anime-cart").innerWidth() / 9;
    var eyebrowsMovingValue = $(".anime-santa").innerHeight() / 120;

    // Walking/Moving functions
    var cartMoving = anime({
        targets: [".anime-cart"],
        easing: 'easeOutSine',
        direction: 'alternate',
        translateX: ['0', cartMovingValue],
        loop: true,
        duration: timeKoef * 3 / 2,
        autoplay: false
    });
    var arm1Moving = anime({
        targets: ["#Arm_1_1_"],
        easing: 'easeOutSine',
        direction: 'alternate',
        rotate: ['0deg', '-20deg'],
        loop: true,
        duration: timeKoef * 3 / 2,
        autoplay: false
    });
    var arm2Moving = anime({
        targets: ["#Arm_2_1_"],
        easing: 'easeOutSine',
        direction: 'alternate',
        rotate: ['-15deg', '30deg'],
        loop: true,
        duration: timeKoef * 3 / 2,
        autoplay: false
    });
    var leg1Moving = anime({
        targets: ["#Leg_1_1_"],
        easing: 'linear',
        direction: 'alternate',
        rotate: ['-30deg', '-5deg'],
        loop: true,
        duration: timeKoef,
        autoplay: false
    });
    var leg2Moving = anime({
        targets: ["#Leg_2_1_"],
        easing: 'linear',
        direction: 'alternate',
        rotate: ['30deg', '5deg'],
        loop: true,
        duration: timeKoef,
        autoplay: false
    });
    var headMoving = anime({
        targets: ["#Head_1_"],
        easing: 'linear',
        direction: 'alternate',
        rotate: ['0deg', '5deg'],
        loop: true,
        duration: timeKoef * 3 / 2,
        autoplay: false
    });
    var eyebrowsMoving = anime({
        targets: ["#Eyebrow_1_1_", "#Eyebrow_2_1_", "#EyebrowShadow_1_1_", "#EyebrowShadow_2_1_"],
        easing: 'easeOutSine',
        direction: 'alternate',
        translateY: ['0', -eyebrowsMovingValue],
        loop: true,
        duration: timeKoef * 3 / 2,
        autoplay: false
    });
    var mustache1Moving = anime({
        targets: ["#Mustache_1_1_", "#MustacheShadow_1_1_"],
        easing: 'easeOutSine',
        direction: 'alternate',
        rotate: ['0deg', '5deg'],
        loop: true,
        duration: timeKoef * 3 / 2,
        autoplay: false
    });
    var mustache2Moving = anime({
        targets: ["#Mustache_2_1_", "#MustacheShadow_2_1_"],
        easing: 'easeOutSine',
        direction: 'alternate',
        rotate: ['0deg', '-5deg'],
        loop: true,
        duration: timeKoef * 3 / 2,
        autoplay: false
    });

    // Shaking/Waving functions
    var handUp = anime({
        targets: ["#Arm_2_1_"],
        easing: 'linear',
        direction: 'alternate',
        rotate: ['-15deg', '-120deg'],
        loop: false,
        duration: timeKoef,
        autoplay: false
    })
    var handDown = anime({
        targets: ["#Arm_2_1_"],
        easing: 'linear',
        direction: 'alternate',
        rotate: ['-120deg', '-15deg'],
        loop: false,
        duration: timeKoef,
        autoplay: false
    })
    var handWaving = anime({
        targets: ["#Arm_2_1_"],
        easing: 'linear',
        direction: 'alternate',
        rotate: ['-120deg', '-100deg'],
        loop: true,
        duration: 500,
        autoplay: false
    });
    var headShaking = anime({
        targets: ["#Head_1_"],
        easing: 'linear',
        direction: 'alternate',
        rotate: ['0deg', '2deg'],
        loop: true,
        duration: timeKoef,
        autoplay: false
    });
    var eyebrowsShaking = anime({
        targets: ["#Eyebrow_1_1_", "#Eyebrow_2_1_", "#EyebrowShadow_1_1_", "#EyebrowShadow_2_1_"],
        easing: 'easeOutSine',
        direction: 'alternate',
        translateY: ['0', -eyebrowsMovingValue],
        loop: true,
        duration: timeKoef,
        autoplay: false
    });
    var mustache1Shaking = anime({
        targets: ["#Mustache_1_1_", "#MustacheShadow_1_1_"],
        easing: 'easeOutSine',
        direction: 'alternate',
        rotate: ['0deg', '5deg'],
        loop: true,
        duration: timeKoef,
        autoplay: false
    });
    var mustache2Shaking = anime({
        targets: ["#Mustache_2_1_", "#MustacheShadow_2_1_"],
        easing: 'easeOutSine',
        direction: 'alternate',
        rotate: ['0deg', '-5deg'],
        loop: true,
        duration: 1000,
        autoplay: false
    });
    var bubleShow = anime({
        targets: ["#Speech_Bubble_1_"],
        easing: 'linear',
        direction: 'alternate',
        opacity: [0, 1],
        loop: false,
        duration: timeKoef,
        autoplay: false
    })

    // Walking/Moving start/stop
    function walkingStart() {
        arm1Moving.play();
        arm2Moving.play();
        leg1Moving.play();
        leg2Moving.play();
        cartMoving.play();
        headMoving.play();
        eyebrowsMoving.play();
        mustache1Moving.play();
        mustache2Moving.play();
    }
    function walkingStop() {
        arm1Moving.pause();
        arm2Moving.pause();
        leg1Moving.pause();
        leg2Moving.pause();
        cartMoving.pause();
        headMoving.pause();
        eyebrowsMoving.pause();
        mustache1Moving.pause();
        mustache2Moving.pause();
    }

    // Shaking/Waving start/stop
    function shakingStart() {
        handWaving.play();
        headShaking.play();
        eyebrowsShaking.play();
        mustache1Shaking.play();
        mustache2Shaking.play();
    }
    function shakingStop() {
        handWaving.pause();
        headShaking.pause();
        eyebrowsShaking.pause();
        mustache1Shaking.pause();
        mustache2Shaking.pause();
    }

    var santaHide = anime({
        targets: [".anime-main"],
        easing: 'linear',
        direction: 'alternate',
        opacity: [1, 0],
        loop: false,
        duration: timeKoef * 4,
        autoplay: false
    })

    // Anime start
    $(".anime").addClass("active animated");
    var mainTimeout = setTimeout(function () {
        $(".anime-main").addClass("active");
        walkingStart();
        setTimeout(function () {
            walkingStop();
        }, timeKoef * koefValue);
        setTimeout(function () {
            bubleShow.play();
        }, (timeKoef * koefValue) + (timeKoef * 2));
        setTimeout(function () {
            handUp.play();
            setTimeout(function () {
                shakingStart();
                setTimeout(function () {
                    handDown.play();
                    setTimeout(function () {
                        santaHide.play();
                        walkingStart();
                        $(".anime-main").addClass("hide");
                        setTimeout(function () {
                            $(".anime").removeClass("animated");
                            setTimeout(function () {
                                $(".anime").removeClass("active");
                                clearTimeout(mainTimeout);
                                $(".anime").remove();
                            }, 1000);
                        }, timeKoef * 5);
                    }, timeKoef);
                }, timeKoef * 6);
            }, timeKoef);
        }, (timeKoef * koefValue) + (timeKoef * 3));
    }, 1000);
    $(".js-anime-close").on("click", function (e) {
        $(".anime").removeClass("animated");
        setTimeout(function () {
            $(".anime").removeClass("active");
            clearTimeout(mainTimeout);
            $(".anime").remove();
        }, 1000);
    });
}

function snow() {
    //canvas init
    if ($("#snow").length) {
        var canvas = document.getElementById("snow");
        var ctx = canvas.getContext("2d");

        //canvas dimensions
        var W = window.innerWidth;
        var H = window.innerHeight;
        canvas.width = W;
        canvas.height = H;

        //snowflake particles
        var mp = $(window).innerWidth() / 15; //max particles
        var particles = [];
        for (var i = 0; i < mp; i++) {
            particles.push({
                x: Math.random() * W, //x-coordinate
                y: Math.random() * H, //y-coordinate
                r: Math.random() * 4 + 1, //radius
                d: Math.random() * mp //density
            })
        }

        //Lets draw the flakes
        function draw() {
            ctx.clearRect(0, 0, W, H);

            ctx.fillStyle = "rgba(236, 226, 226, 0.8)";
            ctx.beginPath();
            for (var i = 0; i < mp; i++) {
                var p = particles[i];
                ctx.moveTo(p.x, p.y);
                ctx.arc(p.x, p.y, p.r, 0, Math.PI * 2, true);
            }
            ctx.fill();
            update();
        }

        //Function to move the snowflakes
        //angle will be an ongoing incremental flag. Sin and Cos functions will be applied to it to create vertical and horizontal movements of the flakes
        var angle = 0;
        function update() {
            angle += 0.01;
            for (var i = 0; i < mp; i++) {
                var p = particles[i];
                //Updating X and Y coordinates
                //We will add 1 to the cos function to prevent negative values which will lead flakes to move upwards
                //Every particle has its own density which can be used to make the downward movement different for each flake
                //Lets make it more random by adding in the radius
                p.y += Math.cos(angle + p.d) + 1 + p.r / 2;
                p.x += Math.sin(angle) * 2;

                //Sending flakes back from the top when it exits
                //Lets make it a bit more organic and let flakes enter from the left and right also.
                if (p.x > W + 5 || p.x < -5 || p.y > H) {
                    if (i % 3 > 0) //66.67% of the flakes
                    {
                        particles[i] = { x: Math.random() * W, y: -10, r: p.r, d: p.d };
                    }
                    else {
                        //If the flake is exitting from the right
                        if (Math.sin(angle) > 0) {
                            //Enter from the left
                            particles[i] = { x: -5, y: Math.random() * H, r: p.r, d: p.d };
                        }
                        else {
                            //Enter from the right
                            particles[i] = { x: W + 5, y: Math.random() * H, r: p.r, d: p.d };
                        }
                    }
                }
            }
        }

        //animation loop
        setInterval(draw, 33);
    }
}